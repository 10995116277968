<template>
  <div>
    <component :is="print_type" :stock_transfer="stock_transfer" />
  </div>
</template>

<script>
import common_printing from "@/components/stock-transfer/sections/PrintCommonStockTransfer.vue";
import thermal_printing from "@/components/stock-transfer/sections/PrintThermalStockTransfer.vue";
export default {
  components: { common_printing, thermal_printing },

  data() {
    return {
      stock_transfer: {
        company_origin: {},
        company_destination: {},
        created_by: {},
        stock_transfer_products: [],
      },
    };
  },

  async created() {
    this.$loading.start();
    await this.$http

      .show("stock/stock-transfer", this.$route.params.id)
      .then((response) => {
        this.stock_transfer = response.transfer;
        this.$loading.finish();
      })
      .catch((error) => {
        this.$loading.finish();
      });
  },

  computed: {
    print_type() {
      return this.$store.getters["app/getTypePrint"];
    },
  },
};
</script>

<style>
</style>